/**
 * base.scss
 */
/**
 * Global variables
 */
a {
  transition-property: color, background, border-color, opacity, transform, box-shadow, outline;
  transition-duration: 200ms; }
  a:active:hover {
    transition: none; }

/*
  [1] Inherit box-sizing instead of setting the as the global default. This
      makes it easier to use 3rd party modules written with content-box.

  [2] I dislike it when the cursor changes on text hover. This makes all
      elements inherit `cursor: default` from the html element, then we reapply
      the correct cursor for buttons, inputs and anchor links later.

  [3] In my opinion, relative makes a lot more sense as a default value than
      static.

  [4] Set entire block of font rules to apple-system-body, this sets
      scaling of text respect the system setting on iOS devices. Note that
      font-size is decoupled from the other font-related properties, since
      setting it on the same selector would override the implicit font-size
      from the font: -apple-system-body declaration.

  [5] Reapply cursor: pointer to all anchor links as it was removed at [2]. This
      also covers anchor links without a [href] attribute so let it stay even if
      you remove the cursor override.
*/
*,
::before,
::after {
  box-sizing: inherit;
  /* [1] */
  cursor: inherit;
  /* [2] */
  position: relative;
  /* [3] */ }

html {
  box-sizing: border-box;
  cursor: default;
  height: 100%;
  font: -apple-system-body;
  /* [4a] */
  font-family: "Brix Sans", system-ui;
  font-weight: 400;
  line-height: 1;
  color: #39393a;
  transition: color 200ms; }
  html.wf-loading {
    color: transparent; }

body {
  display: flex;
  min-height: 100%;
  font-size: 100%;
  /* [4b] */
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: white;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-font-feature-settings: "liga" on; }

.root {
  display: flex;
  flex: 1 0 100%;
  max-width: 100%; }

a {
  color: inherit;
  text-decoration: none;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  /* [4] */ }
  a:focus {
    outline: 0; }
  a:active:hover {
    opacity: .5; }
  a[disabled] {
    cursor: not-allowed; }

svg,
img {
  width: auto;
  height: auto;
  max-width: 100%;
  display: inline-block;
  vertical-align: middle; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

mark {
  background: #1ea2b1; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

ul,
ol {
  margin: 0;
  padding-left: 0;
  line-height: 1;
  list-style: none; }

textarea {
  resize: vertical; }

form {
  margin: 0; }

picture {
  display: block; }
  picture > video {
    display: none; }

address {
  line-height: 1.5; }

hr {
  border: none;
  border-bottom: 1px solid #bdbdbd;
  margin-top: 2rem;
  margin-bottom: 2rem; }
  hr:first-child {
    margin-top: 0; }
  hr:last-child {
    margin-bottom: 0; }

[data-whatintent="keyboard"] :not(input):not([tabindex="-1"]):not(.no-focus):focus {
  box-shadow: 0 0 0.3em 0.15em #5e9ed6; }
