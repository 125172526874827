/**
 * typography.scss
 */
/**
 * Global variables
 */
h1,
.h1, h2,
.h2, h3,
.h3, h4,
.h4, h5,
.h5, h6,
.h6 {
  margin: 0;
  font: 300 1em/1.15 "Brix Sans", system-ui;
  color: #212121;
  transition: color 200ms; }
  .wf-loading h1,
  .wf-loading .h1, .wf-loading h2,
  .wf-loading .h2, .wf-loading h3,
  .wf-loading .h3, .wf-loading h4,
  .wf-loading .h4, .wf-loading h5,
  .wf-loading .h5, .wf-loading h6,
  .wf-loading .h6 {
    color: transparent !important; }
  h1:not(:first-child),
  .h1:not(:first-child), h2:not(:first-child),
  .h2:not(:first-child), h3:not(:first-child),
  .h3:not(:first-child), h4:not(:first-child),
  .h4:not(:first-child), h5:not(:first-child),
  .h5:not(:first-child), h6:not(:first-child),
  .h6:not(:first-child) {
    margin-top: 3.925rem; }

h1,
.h1, h2,
.h2, h3,
.h3 {
  margin-bottom: -0.185em; }

h4,
.h4, h5,
.h5, h6,
.h6 {
  font-family: "Brix Sans", system-ui;
  line-height: 1.5;
  font-weight: 600; }

h1,
.h1 {
  font-size: 2.75rem;
  letter-spacing: -0.04em; }
  @media (max-width: 599px) {
    h1,
    .h1 {
      font-size: 2em; } }
  @media (min-width: 600px) and (max-width: 1213px) {
    h1,
    .h1 {
      font-size: 2.25rem; } }

h2,
.h2 {
  font-size: 2.25rem;
  letter-spacing: -0.04em; }
  @media (max-width: 599px) {
    h2,
    .h2 {
      font-size: 2em; } }

h3,
.h3 {
  font-size: 1.5rem;
  font-weight: 400; }

h4,
.h4 {
  font-size: 1.125rem; }

h5,
.h5 {
  font-size: 1rem; }

h6,
.h6 {
  font-size: 1rem; }

.subheading {
  display: block;
  color: #9e9e9e;
  transition: color 200ms; }
  .wf-loading .subheading {
    color: transparent !important; }

p > a,
p > i > a,
p > b > a,
p > em > a,
p > strong > a, .typography ol > li > a, .typography ul > li > a {
  border-bottom: 1px solid currentColor; }
  p > a:focus,
  p > i > a:focus,
  p > b > a:focus,
  p > em > a:focus,
  p > strong > a:focus, .typography ol > li > a:focus, .typography ul > li > a:focus, p > a:hover,
  p > i > a:hover,
  p > b > a:hover,
  p > em > a:hover,
  p > strong > a:hover, .typography ol > li > a:hover, .typography ul > li > a:hover {
    color: #1ea2b1; }

p {
  line-height: 1.5;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }
  p:first-child {
    margin-top: 0; }
  p:last-child {
    margin-bottom: 0; }

mark {
  padding: .2em;
  background: #fff6db; }

strong, b {
  font-weight: 600;
  color: #212121;
  transition: color 200ms; }
  .wf-loading strong, .wf-loading b {
    color: transparent !important; }

.typography {
  line-height: 1.5;
  max-width: 80ch; }
  .typography ol, .typography ul {
    line-height: 1.5;
    padding-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem; }
    .typography ol:first-child, .typography ul:first-child {
      margin-top: 0; }
    .typography ol:last-child, .typography ul:last-child {
      margin-bottom: 0; }
    .typography ol > li, .typography ul > li {
      padding-left: 0.5rem;
      margin-top: 1.25rem; }
    .typography ol ul, .typography ul ul {
      list-style-type: circle; }
  .typography ul {
    list-style-type: disc;
    list-style-image: url("/assets/images/checkmark--primary.svg");
    padding-left: 1.5rem; }
  .typography ol {
    list-style-type: decimal; }
  .typography [style*="font-size"] {
    font-size: inherit !important; }

.lead {
  font-size: 1.125em; }
